import * as amplitude from "@amplitude/analytics-browser";
import { navigate } from "vike/client/router";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "@/api/axios";
import { useChord } from "@/hooks/useChord";
import { usePageContext } from "vike-react/usePageContext";
import { useUser } from "@/hooks/useUser";

import type { CurrentUser } from "@/types/user";

export default function LayoutAuth({ children }: { children: React.ReactNode }) {
  const chord = useChord();
  const { setUser } = useUser();

  const pageContext = usePageContext();
  const { urlPathname } = pageContext;

  useEffect(() => {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, {
      autocapture: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: true,
        fileDownloads: true,
        elementInteractions: true,
      },
    });
  }, []);

  useEffect(() => {
    chord.page();
  }, [chord, urlPathname]);

  const { data: user, isLoading } = useQuery({
    queryKey: ["current-user"],
    queryFn: async () => {
      const { data } = await api.get<CurrentUser>("/v1/current_user");
      amplitude.setUserId(data.id);
      setUser(data);
      return data;
    },
    retry: false,
  });

  const renderChildren = () => {
    if (isLoading) {
      return (
        <div className="h-screen flex items-center justify-center">
          <span className="loading loading-spinner loading-lg text-primary"></span>
        </div>
      );
    }

    if (user) {
      navigate("/feed");
    }

    return children;
  };

  return <div className="w-full h-full">{renderChildren()}</div>;
}
